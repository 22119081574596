.logo {
  padding: 10px;
}

.menu-header {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  margin-left: 16px;
  padding: 0 12px;

  /* font-weight: 700; */
}

.menu-header:hover {
  cursor: pointer;
  color: white;
  background-image: linear-gradient(120deg, #0a78b2 0, #45b6bc);
}

.line-header {
  height: 40px;
  border-left: 0.1px solid #fff;
}

/* .overlay-menu-header {
    width: 250px;
} */

.avatar {
  vertical-align: middle;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

@media all and (max-width: 375px),
  only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 1024px),
  only screen and (min--moz-device-pixel-ratio: 2) and (max-width: 1024px),
  only screen and (-o-min-device-pixel-ratio: 2/1) and (max-width: 1024px),
  only screen and (min-device-pixel-ratio: 2) and (max-width: 1024px),
  only screen and (min-resolution: 192dpi) and (max-width: 1024px),
  only screen and (min-resolution: 2dppx) and (max-width: 1024px) {
  .menu {
    color: #ffffff;
    display: flex;
    align-items: center;
    font-size: 16px;
  }
  .xs {
    max-width: 100px;
    visibility: hidden;
  }
  .xs span::after {
    visibility: visible;
    content: "Menu";
  }
}
