.document {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.document .react-pdf__Document {
	z-index: 1;
	display: flex;
	flex-direction: row;
	width: fit-content;
}
.document .react-pdf__Page {
	max-width: calc(100% - 2em);
	border-radius: 2px;
	box-shadow: 20px 20px 60px #cfd4d9, -20px -20px 60px #ffffff;
	margin: 1em;
}
.document .react-pdf__Page canvas {
	max-width: 100%;
	height: auto !important;
}
