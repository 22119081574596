@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~antd/dist/antd.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.GreyTooltip {
  background-color: #fafafa !important;
  color: grey !important;
}

.ant-picker-calendar .calendar-task {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.ant-picker-calendar .calendar-task:hover {
  background: #efefef !important;
}
.ant-picker-calendar .calendar-tasklist {
  white-space: nowrap !important;
  /* overflow: hidden !important; */
  text-overflow: ellipsis !important;

  height: 100% !important;
}

.table-col-header-highligth {
  background-color: #c3ecff;
  border: 1px solid #91d5ff;
  color: #096dd9;
  text-align: center;
}
.table-col-header {
  border: 1px solid transparent;
}

.my-select-container .ant-select .ant-select-selector {
  border-radius: 8px;
}
.my-select-container .ant-radio-group .ant-radio-button-wrapper {
  border-radius: 8px;
}
.collapse-small-headers .ant-collapse-header {
  padding: 6px 12px !important;
}
.ant-input-number-handler-wrap,
.ant-select-dropdown,
.ant-picker-panel-container {
  border-radius: 8px;
}
.ant-message-notice-content {
  border-radius: 16px;
  background: linear-gradient(145deg, #ffffff, #f6f6f6);
}

.ant-notification-notice {
  padding: 12px 16px;
  border-radius: 16px;
}

.ant-popover-inner {
  border-radius: 8px;
}

.ant-modal-content {
  border-radius: 16px;
}
.ant-modal-header {
  border-radius: 16px 16px 0 0;
}

.ant-upload-list-item-info .anticon-delete {
  color: red;
  opacity: 1;
}
.ant-upload-list-item-info {
  border-radius: 8px;
  border: 1px solid #69b1ff;
  padding: 16px 8px;
  font-size: 12px;
}
.biomarkers-preview-card .ant-modal-body {
  padding: 12px 24px;
}

.target-on-hover {
  transition: all 0.15s;
}

// flat to floating
.trigger-on-hover .target-on-hover {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);

  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;

  -webkit-transition-property: transform;
  transition-property: transform;

  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.trigger-on-hover:hover .target-on-hover,
.trigger-on-hover:focus .target-on-hover,
.trigger-on-hover:active .target-on-hover {
  -webkit-transform: translateY(-4px);
  transform: translateY(-4px);
  box-shadow: 0 6px 10px rgba(5, 26, 180, 0.35);
}
//floating to floating more
.trigger-on-hover .target-on-hover-prev-floating {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;

  -webkit-transition-property: transform;
  transition-property: transform;

  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.trigger-on-hover:hover .target-on-hover-prev-floating,
.trigger-on-hover:focus .target-on-hover-prev-floating,
.trigger-on-hover:active .target-on-hover-prev-floating {
  -webkit-transform: translateY(-4px);
  transform: translateY(-4px);
  box-shadow: 0 4px 8px rgba(5, 26, 180, 0.35);
}

.loading-svg {
  filter: drop-shadow(5px 5px 15px #ce6a3f) drop-shadow(-5px -5px 15px #ff9055);
}
.welcome-svg {
  filter: drop-shadow(5px 5px 15px #2b7376) drop-shadow(-5px -5px 15px #5ff9ff);
}
.login-svg {
  filter: drop-shadow(9px 9px 18px #b2b2b2) drop-shadow(-9px -9px 18px #f0f0f0);
}
