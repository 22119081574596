.gradient-bg {
  background-image: linear-gradient(180deg, #0a78b2 0, #45b6bc);
}
.diagonal {
  position: relative;
  height: 350px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 40px));
}
.under-diagonal {
  margin-top: -50px;
}
